import { axiosClient, generateUrlString, getQueryDataObject } from "Utils";
import {
  CloseBulkTicketInput,
  CreateTicketInput,
  GetAllTicketsInput,
  GetQueryInput,
  TicketFeedbackInput,
} from "shared";

export const getAllTickets = async ({
  customerUserId,
  ...options
}: GetAllTicketsInput) => {
  try {
    const data = getQueryDataObject(options);
    const url = await generateUrlString({
      baseUrl: `/v2/agent/tickets/user/${customerUserId}`,
      data,
    });
    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (e: any) {
    throw new Error(e?.message || "Unable to get all tickets");
  }
};

export const getAllTicketsOfMembers = async ({
  customerUserId,
  ...options
}: GetAllTicketsInput) => {
  try {
    options.filter = [
      ...(options.filter ? options.filter : []),
      ...(Array.isArray(options?.members) && options?.members?.length > 0
        ? [
            {
              column: "customer_users.mapping_id",
              operator: "=",
              value: options?.members,
            },
          ]
        : []),
    ];
    const data = getQueryDataObject(options);
    const url = await generateUrlString({
      baseUrl: `/agent/fetch/tickets/team/privilaged/user/${customerUserId}`,
      data,
    });
    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (e: any) {
    throw new Error(e?.message || "Unable to get all tickets");
  }
};

export const getAllTicketStatus = async (customerUserId: string) => {
  try {
    const data = getQueryDataObject({
      filter: [{ column: "tickets.is_archived", operator: "=", value: false }],
    });
    const url = await generateUrlString({
      baseUrl: `/agent/fetch/status/ticket/msp_id/${customerUserId}`,
      data,
    });
    const response = await axiosClient.get(url);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (e: any) {
    throw new Error(e?.message || "Unable to get all tickets");
  }
};

export const createTicket = async (data: CreateTicketInput) => {
  try {
    const response = await axiosClient.post(
      `/agent/create/ticket/msp_id`,
      data
    );
    const resData = response.data;

    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (e: any) {
    throw new Error(e?.message || "Unable to create ticket at the moment");
  }
};

export const getTicketById = async (ticketId: string) => {
  try {
    const response = await axiosClient.get(
      `/agent/get_by_id/ticket/${ticketId}/msp_id`
    );
    const resData = response.data;

    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (e: any) {
    throw new Error(e?.message || "Unable to get Ticket at the moment");
  }
};

export const closeBulkTickets = async (data: CloseBulkTicketInput) => {
  try {
    const response = await axiosClient.patch(
      `/agent/bulk_update/ticket/msp_id/close`,
      { ...data, status_id: "953b24ef-2167-49f9-88d9-a06cca165753" }
    );
    const resData = response.data;

    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (e: any) {
    throw new Error(e?.message || "Unable cancel tickets");
  }
};

export const closeTicketById = async (ticketId: string, statusId: string) => {
  try {
    const response = await axiosClient.patch(
      `/agent/update/ticket/${ticketId}`,
      { status_id: statusId }
    );
    const resData = response.data;

    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (e: any) {
    throw new Error(e?.message || "Unable cancel ticket");
  }
};

export const addTicketFeedback = async ({
  serviceId,
  ...data
}: TicketFeedbackInput) => {
  try {
    const response = await axiosClient.post(
      `/agent/user/feedback/ticket/${serviceId}/msp_id`,
      data
    );
    const resData = response.data;

    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (e: any) {
    throw new Error(e?.message || "Unable to add feedback at the moment");
  }
};

export const getTicketTemplates = async (options: GetQueryInput) => {
  try {
    const data = getQueryDataObject(options);
    const url = await generateUrlString({
      baseUrl: `/agent/configuration/fetch/ticket_template/msp/msp_id`,
      data,
    });
    const response = await axiosClient.get(url);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (e: any) {
    throw new Error(e?.message || "Unable to get ticket templates");
  }
};
