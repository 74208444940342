import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addTicketFeedback,
  closeBulkTickets,
  closeTicketById,
  createTicket,
  getAllTicketStatus,
  getAllTickets,
  getAllTicketsOfMembers,
  getTicketById,
  getTicketTemplates,
} from "controller/api";
import { GetAllTicketsInput, GetQueryInput, TicketFeedbackInput } from "shared";
import { addMixpanelEvent } from "Utils/helpers/mixpanelLogger";

export const useGetAllTickets = (options: GetAllTicketsInput) => {
  return useQuery({
    enabled: !!options.customerUserId && options.customerUserId.length > 0,
    queryKey: [
      "tickets",
      options.filter,
      options.pageNumber,
      options.perPageCount,
      options.sortDirection,
      options.sortColumn,
      options.searchQuery,
      ...(options.members || []),
    ],
    queryFn: () => getAllTickets(options),
  });
};
export const useGetAllTicketStatus = (customerUserId: string) => {
  return useQuery({
    enabled: !!customerUserId && customerUserId.length > 0,
    queryKey: ["tickets", "status"],
    queryFn: () => getAllTicketStatus(customerUserId),
  });
};

export const useCreateTicket = () => {
  const queryClient = useQueryClient();
  // const userId = useGlobalStore((state) => state.userId);
  return useMutation({
    mutationFn: createTicket,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({ queryKey: ["tickets"] });

      addMixpanelEvent({
        event: "create_ticket",
        data: variables,
      });
    },
  });
};

export const useGetTicketById = (ticketId?: string | null) => {
  return useQuery({
    queryKey: ["tickets", ticketId],
    enabled: !!ticketId && ticketId.length > 0,
    queryFn: () => getTicketById(ticketId || ""),
  });
};

export const useCloseBulkTickets = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: closeBulkTickets,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({ queryKey: ["tickets"] });
    },
  });
};

export const useCloseTicketById = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) =>
      closeTicketById(id, "953b24ef-2167-49f9-88d9-a06cca165753"),
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({ queryKey: ["tickets"] });
    },
  });
};

export const useReopenTicketById = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) =>
      closeTicketById(id, "30f434a9-4e66-4bea-86e3-86bb945035b3"),
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({ queryKey: ["tickets"] });
    },
  });
};

export const useAddFeedback = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: TicketFeedbackInput) => addTicketFeedback(data),
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({ queryKey: ["tickets"] });
    },
  });
};

export const useGetTicketTemplates = (options: GetQueryInput) => {
  return useQuery({
    enabled:
      options.searchQuery && options.searchQuery?.length > 1 ? true : false,
    queryKey: [
      "tickets",
      "templates",
      options.filter,
      options.pageNumber,
      options.perPageCount,
      options.sortDirection,
      options.sortColumn,
      options.searchQuery,
    ],
    queryFn: () => getTicketTemplates(options),
  });
};
